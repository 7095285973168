<template>
  <div>
    <!-- 顶部操作栏，带返回功能 -->
    <!-- 此页面用作预览，按照需求预览各种文件 -->
    <div v-if="query.type">
      <div class="video-container"
           v-if="query.type === 'video'">
        <video class="item"
               :src="query.url"
               controlslist="nodownload"
               controls></video>
      </div>
      <div v-else-if="query.type === 'audio'">
        <audio class="music"
               :src="query.url"
               controlslist="nodownload"
               height="100%"
               controls></audio>
      </div>
      <div v-else-if="query.type === 'document'">

        <iframe :src="query.url"
                id="viewer"
                width="100%"
                height="900rem"
                seamless>

        </iframe>
        <!-- <a href="query.url"></a> -->
        <!-- 
                frameborder="0" 
                 
                -->

      </div>

      <div class='info'>信息：
        <span v-show="query.subject_cn">{{query.subject_cn||''}}</span>
        <span v-show="query.subject_name">{{query.subject_name||''}}</span>
        <span v-show="query.user_name">{{query.user_name||''}} </span>
        <span v-show="query.knowledge_desc">{{query.knowledge_desc||''}}</span>
        <span v-show="query.filename">{{query.filename||''}}</span>
        <span v-show="query.created_time"> {{query.created_time}}</span>
      </div>

    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
const query = ref('')
const route = useRoute()
onMounted(() => {
  query.value = route.query
})
/* export default {

  data () {
    return {
      query: {}
    };
  },
  created () {
    this.query = this.$route.query;
    // if (this.query.type === 'document') {
    //   let removeESC = url => {
    //     let reg = /\\/g;
    //     return url.replace(reg, '');
    //   };
    //   this.query.url = removeESC(this.query.url);
    // }
  }
}; */
</script>

<style lang="scss" scoped>
.video-container {
  display: flex;
  width: 100%;
  height: 90vh;
  background: #000;
  .item {
    flex: 1;
    width: inherit;
  }
  vedio {
    height: 640rem;
  }
}
.music {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.info {
  margin: 15rem 45rem;
  font-size: 24rem;
  span {
    display: inline-block;
    margin-right: 20rem;
  }
}
#download {
  display: none;
}
</style>
